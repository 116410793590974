import React, { useState, useEffect } from 'react';
import {
  Tab,
  Button,
  Modal,
  Form,
  Dropdown,
  Checkbox,
  Icon,
} from 'semantic-ui-react';

import AmsAlert from '../../utils/AmsAlert';
import BootstrapTableNext from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  addCenter,
  addClassroom,
  editCenter,
  editClassroom,
  fetchCenters,
  fetchClassrooms,
  fetchActiveCenters,
  enableOrDisableCenter,
  enableOrDisableClassroom,
} from '../../actions/fa2Actions';
import { useDispatch, useSelector } from 'react-redux';

export default function ReviewCenterClassroomManagement({ props }) {
  const dispatch = useDispatch();
  const [centerTotal, setCenterTotal] = useState(0);
  const [classTotal, setClassTotal] = useState(0);
  const [centerPage, setCenterPage] = useState(1);
  const [centerLimit, setCenterLimit] = useState(10);
  const [classPage, setClassPage] = useState(1);
  const [classLimit, setClassLimit] = useState(10);

  const [sortName, setSortName] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [selectedCenter, setSelectedCenter] = useState('');
  const [showAddCenterModal, setShowAddCenterModal] = useState(false);
  const [showAddClassroomModal, setShowAddClassroomModal] = useState(false);
  const [showEditCenterModal, setShowEditCenterModal] = useState(false);
  const [showEditClassroomModal, setShowEditClassroomModal] = useState(false);
  const [newCenterName, setNewCenterName] = useState('');
  const [newClassrooms, setNewClassrooms] = useState(['']);
  const [centers, setCenters] = useState();
  const [activeCenters, setActiveCenters] = useState();
  const [classrooms, setClassrooms] = useState();
  const [editingCenter, setEditingCenter] = useState(null);
  const [editingClassroom, setEditingClassroom] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showActiveStatusIn, _] = useState([
    'Created',
    'Team Assigned',
    'In Analysis',
    'Team Completed',
    'In Progress',
  ]);
  const [reviewStatus, setReviewStatus] = useState('');
  const [activeStatus, setActiveStatus] = useState(false);
  const [reviewId, setReviewId] = useState();

  const { selectedReview } = useSelector(state => state.review);
  let centeri = useSelector(state => state.forms.centers);
  let classroomi = useSelector(state => state.forms.classrooms);
  const activeCentersi = useSelector(state => state.forms.activeCenters);
  const { centerEnableDisableResult } = useSelector(state => state.forms);
  const { classroomEnableDisableResult } = useSelector(state => state.forms);
  const { centerAddedResult } = useSelector(state => state.forms);
  const { classroomAddedResult } = useSelector(state => state.forms);

  const { centerEditedResult } = useSelector(state => state.forms);
  const { classroomEditedResult } = useSelector(state => state.forms);

  const fetchActiveCentersDropdown = id => {
    let revId;
    if (id && id !== '') {
      revId = id;
    } else if (reviewId && reviewId !== '') {
      revId = reviewId;
    }
    if (revId && revId !== '') {
      dispatch(
        fetchActiveCenters({
          reviewId: revId,
        })
      );
    }
  };
  const fetchCenter = id => {
    let revId;
    if (id && id !== '') {
      revId = id;
    } else if (reviewId && reviewId !== '') {
      revId = reviewId;
    }
    if (revId && revId !== '') {
      dispatch(
        fetchCenters({
          filters: {
            reviewId: revId,
          },
          page: centerPage,
          limit: centerLimit,
          sortName,
          sortOrder,
        })
      );
    }
  };

  useEffect(() => {
    const { id } = props.params;
    setReviewId(_ => {
      fetchCenter(id);
      fetchActiveCentersDropdown(id);
      return id;
    });

    if ((!selectedCenter || selectedCenter === '') && activeCenters)
      setSelectedCenter(activeCenters[0].centerId);
  }, []);

  useEffect(() => {
    const { id } = props.params;
    fetchCenter(id);
  }, [paginationOptionsCenter, centerPage, centerLimit]);

  useEffect(() => {
    fetchClassroom();
  }, [paginationOptionsClass, classPage, classLimit]);

  useEffect(() => {
    if (centers?.total) {
      setCenterTotal(centers.total);
    }
  }, [centers]);

  useEffect(() => {
    if (activeCenters) {
      setSelectedCenter(activeCenters[0]?.centerId);
    } else {
      setClassrooms({ data: [] });
    }
  }, [activeCenters]);

  useEffect(() => {
    setCenters(centeri);
  }, [centeri]);

  useEffect(() => {
    setActiveCenters(activeCentersi);
    if (activeCenters) setSelectedCenter(activeCenters[0]?.centerId);
  }, [activeCentersi]);

  useEffect(() => {
    setClassrooms(classroomi);
  }, [classroomi]);

  useEffect(() => {
    fetchCenter();

    fetchActiveCentersDropdown();
  }, [centerEnableDisableResult, centerAddedResult, centerEditedResult]);

  useEffect(() => {
    fetchClassroom();
  }, [
    classroomEnableDisableResult,
    classroomAddedResult,
    classroomEditedResult,
  ]);

  useEffect(() => {
    if (classrooms?.total) {
      setClassTotal(classrooms.total);
    }
  }, [classrooms]);

  const fetchClassroom = () => {
    if (selectedCenter) {
      dispatch(
        fetchClassrooms({
          filters: {
            reviewId,
            centerId: selectedCenter,
          },
          page: classPage,
          limit: classLimit,
          sortName,
          sortOrder,
        })
      );
    }
  };
  useEffect(() => {
    if (selectedCenter) {
      fetchClassroom();
    }
  }, [selectedCenter]);

  useEffect(() => {
    if (selectedReview && selectedReview.reviewStatus) {
      setReviewStatus(selectedReview.reviewStatus);

      IsInActiveStatus(selectedReview.reviewStatus);
    }
  }, [selectedReview.reviewStatus, activeStatus]);

  const resetModal = () => {
    setNewCenterName('');
    setNewClassrooms(['']);
  };

  const toggleCenter = e => {
    const id = parseInt(e.target.parentElement.getAttribute('data-itemid'));
    setCenters(prevCenters => {
      const updatedCenters = prevCenters.data.map(center =>
        center.centerId === id
          ? { ...center, enabled: !center.enabled }
          : center
      );

      return {
        ...prevCenters,
        data: updatedCenters,
      };
    });
    const centerToEdit = centers?.data.find(center => center.centerId === id);
    let requestInput = {
      reviewId,
      centerId: id,
      enabled: !centerToEdit.enabled,
    };
    dispatch(enableOrDisableCenter(requestInput));
  };

  const toggleClassroom = e => {
    const id = parseInt(e.target.parentElement.getAttribute('data-itemid'));
    const classRoomToEdit = classrooms.data.find(
      classroom => classroom.classSampleId === id
    );

    setClassrooms(prevClassrooms => {
      const updatedClassrooms = prevClassrooms.data.map(classroom =>
        classroom.classSampleId === id
          ? { ...classroom, enabled: !classroom.enabled }
          : classroom
      );
      return { ...prevClassrooms, data: updatedClassrooms };
    });
    let requestInput = {
      reviewId,
      classSampleId: classRoomToEdit.classSampleId,
      enabled: !classRoomToEdit.enabled,
    };
    dispatch(enableOrDisableClassroom(requestInput));
  };

  const paginationOptionsCenter = {
    page: centerPage,
    hideSizePerPage: true,
    sizePerPage: centerLimit,
    totalSize: centerTotal,
    sortOrder: sortOrder,
    withFirstAndLast: true,

    firstPageText: 'First',
    prePageText: 'Previous',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
  };

  const paginationOptionsClass = {
    page: classPage,
    hideSizePerPage: true,
    sizePerPage: classLimit,
    totalSize: classTotal,
    sortOrder: sortOrder,
    withFirstAndLast: true,
    firstPageText: 'First',
    prePageText: 'Previous',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
  };

  const validateClassrooms = () => {
    const errors = {};
    const names = newClassrooms.map(name => name.trim().toLowerCase());
    names.forEach((name, index) => {
      if (name && names.indexOf(name) !== index) {
        errors[index] = name + ' ';
      }
    });
    return errors;
  };

  const addNewCenter = () => {
    //first Validation
    const isAnyClassroomEmpty = newClassrooms.some(item => item.trim() === '');
    if (isAnyClassroomEmpty) {
      setErrorMessage(
        'Please provide data for classrooms added or remove the empty classrooms'
      );
      setShowErrorAlert(true);
      return;
    }
    //validate center name
    const isCenterDuplicate = centers?.data.some(
      center =>
        center.centerName.trim().toLowerCase() ===
        newCenterName.trim().toLowerCase()
    );
    const classroomsError = validateClassrooms();
    if (isCenterDuplicate) {
      setErrorMessage(
        'This center name already exists. Please change the center name before adding.'
      );
      setShowErrorAlert(true);
      return;
    }
    if (Object.keys(classroomsError).length > 0) {
      setErrorMessage(
        'Classroom names should be unique.  Please provide unique classroom names before adding.'
      );
      setShowErrorAlert(true);
      return;
    }
    const newClassroomsTrimmed = newClassrooms.map(classroom =>
      classroom.trim()
    );
    const request = {
      centerName: newCenterName.trim(),
      classrooms: newClassroomsTrimmed,
      reviewId,
    };
    dispatch(addCenter(request));

    setShowAddCenterModal(false);
    setNewCenterName('');
    setNewClassrooms(['']);
  };

  const addNewClassroom = () => {
    const isAnyClassroomEmpty = newClassrooms.some(item => item.trim() === '');
    if (isAnyClassroomEmpty) {
      setErrorMessage(
        'Please provide data for classrooms added or remove the empty classrooms'
      );
      setShowErrorAlert(true);
      return;
    }

    const classroomsError = validateClassrooms();
    let errors = '';
    const newClassroomsTrimmedLc = newClassrooms.map(classroom =>
      classroom.trim().toLowerCase()
    );
    const isClassroomDuplicate = classrooms.data.some(
      classroom =>
        newClassroomsTrimmedLc.includes(
          classroom.classRoomName.trim().toLowerCase()
        ) && classroom.centerId === selectedCenter
    );
    if (isClassroomDuplicate) {
      setErrorMessage(
        'Classroom names should be unique. Please provide unique classroom names before adding.'
      );
      setShowErrorAlert(true);
      return;
    }
    if (Object.keys(classroomsError.length > 0)) {
      errors += Object.values(classroomsError).join('\r\n');
    }
    if (errors.trim().length > 0) {
      setErrorMessage(
        'Classroom names should be unique. Please provide unique classroom names before adding.'
      );
      setShowErrorAlert(true);
      return;
    }
    const newClassroomsTrimmed = newClassrooms.map(classroom =>
      classroom.trim()
    );
    let request = {
      centerId: selectedCenter,
      classrooms: newClassroomsTrimmed,
      centerName: centers.data.find(c => c.centerId == selectedCenter)
        .centerName,
      reviewId,
    };
    dispatch(addClassroom(request));
    setShowAddClassroomModal(false);
    setNewClassrooms(['']);
  };

  const addNewClassroomField = () => {
    setNewClassrooms([...newClassrooms, '']);
  };

  const removeClassroomField = index => {
    setNewClassrooms(newClassrooms.filter((_, i) => i !== index));
  };

  const editRecentCenter = () => {
    //validate center name
    const isCenterDuplicate = centers.data.some(
      center =>
        center.centerName.trim().toLowerCase() ===
        editingCenter.centerName.trim().toLowerCase()
    );

    if (isCenterDuplicate) {
      setErrorMessage(
        editingCenter.centerName +
          ' already exists. Please update center name or select Cancel to exit editing.'
      );
      setShowErrorAlert(true);
      return;
    }
    const request = {
      centerId: editingCenter.centerId,
      reviewId,
      centerName: editingCenter.centerName,
    };
    dispatch(editCenter(request));
    setShowEditCenterModal(false);
    setEditingCenter(null);
  };

  const editRecentClassroom = () => {
    const isClassroomDuplicate = classrooms.data.some(
      classroom =>
        classroom.classRoomName.trim().toLowerCase() ===
          editingClassroom.classRoomName.trim().toLowerCase() &&
        classroom.centerId === editingClassroom.centerId
    );
    if (isClassroomDuplicate) {
      setErrorMessage(
        editingClassroom.classRoomName +
          ' already exists. Please update classroom name or select Cancel to exit editing.'
      );
      setShowErrorAlert(true);
      return;
    }
    let request = {
      classSampleId: editingClassroom.classSampleId,
      reviewId,
      centerId: selectedCenter,
      classroomName: editingClassroom.classRoomName,
    };
    dispatch(editClassroom(request));
    setShowEditClassroomModal(false);
    setEditingClassroom(null);
  };

  const centerColumns = [
    {
      dataField: 'centerName',
      text: 'Center Name',
      style: { width: '80%' },
      formatter: (cell, row) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {cell.replace(/ /g, '\u00A0')}
          {row.new && (
            <div
              style={{
                display: 'inline-block',
                margin: '0px 20px',
                padding: '0px 16px',
                backgroundColor: '#ffffff', // white background
                color: '#000', // black text
                borderRadius: '18px',
                border: '1px solid blue',
                fontSize: '12px',
                fontWeight: 'regular',
                fontFamily: 'Arial,  sans-serif',
                lineHeight: 1.4,
              }}
            >
              New
            </div>
          )}
        </div>
      ),
    },
    {
      dataField: 'enabled',
      text: 'Active?',
      style: { width: '15%' },
      formatter: (cell, row) => (
        <Form.Field
          name="enabled"
          toggle
          label={{
            children: cell === true ? 'On\u00A0\u00A0\u00A0' : 'Off',
            htmlFor: `centerEnableDisable-${row.centerId}`,
          }}
          control={Checkbox}
          role="group"
          value={cell.toString()}
          checked={cell}
          onChange={e => {
            toggleCenter(e);
          }}
          id={`centerEnableDisable-${row.centerId}`}
          aria-labelledby={`centerEnableDisable-${row.centerId}`}
          data-itemid={`${row.centerId}`}
        />
      ),
    },

    {
      dataField: 'edit',
      text: 'Edit',

      style: { width: '10%' },
      formatter: (cell, row) => (
        <Button
          icon="pencil"
          style={{
            backgroundColor: 'transparent',
          }}
          onClick={() => {
            setEditingCenter(row);
            setShowEditCenterModal(true);
          }}
          aria-label={`Edit ${row.centerName}`}
        />
      ),
    },
  ];

  const classroomColumns = [
    {
      dataField: 'classRoomName',
      text: 'Classroom Name',

      style: { width: '80%' },
      formatter: (cell, row) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {cell.replace(/ /g, '\u00A0')}
          {row.new && (
            <div
              style={{
                display: 'inline-block',
                margin: '0px 20px',
                padding: '0px 16px',
                backgroundColor: '#ffffff', // white background
                color: '#000', // black text
                borderRadius: '18px',
                border: '1px solid blue',
                fontSize: '12px',
                fontWeight: 'regular',
                fontFamily: 'Arial,  sans-serif',
                lineHeight: 1.4,
              }}
            >
              New
            </div>
          )}
        </div>
      ),
    },
    {
      dataField: 'enabled',
      text: 'Active?',

      style: { width: '15%' },
      formatter: (cell, row) => (
        <Form.Field
          name="enabled"
          toggle
          label={{
            children: cell === true ? 'On\u00A0\u00A0\u00A0' : 'Off',
            htmlFor: `classroomEnableDisable-${row.classSampleId}`,
          }}
          control={Checkbox}
          role="group"
          value={cell.toString()}
          checked={cell}
          onChange={e => {
            toggleClassroom(e);
          }}
          id={`classroomEnableDisable-${row.classSampleId}`}
          aria-labelledby={`classroomEnableDisable-${row.classSampleId}`}
          data-itemid={`${row.classSampleId}`}
        />
      ),
    },
    {
      dataField: 'edit',
      text: 'Edit',

      style: { width: '10%' },
      formatter: (cell, row) => (
        <Button
          icon="pencil"
          style={{
            backgroundColor: 'transparent',
          }}
          onClick={() => {
            setEditingClassroom(row);
            setShowEditClassroomModal(true);
          }}
          aria-label={`Edit ${row.classRoomName}`}
        />
      ),
    },
  ];

  const centerColumnsWoActive = [
    {
      dataField: 'centerName',
      text: 'Center Name',

      style: { width: '98%' },
      formatter: (cell, row) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {cell}
          {row.new && (
            <div
              style={{
                display: 'inline-block',
                margin: '0px 20px',
                padding: '0px 16px',
                backgroundColor: '#ffffff', // white background
                color: '#000', // black text
                borderRadius: '18px',
                border: '1px solid blue',
                fontSize: '12px',
                fontWeight: 'regular',
                fontFamily: 'Arial,  sans-serif',
                lineHeight: 1.4,
              }}
            >
              New
            </div>
          )}
        </div>
      ),
    },
  ];

  const classroomColumnsWoActive = [
    {
      dataField: 'classRoomName',
      text: 'Classroom Name',
      style: { width: '98%' },
      formatter: (cell, row) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {cell}
          {row.new && (
            <div
              style={{
                display: 'inline-block',
                margin: '0px 20px',
                padding: '0px 16px',
                backgroundColor: '#ffffff', // white background
                color: '#000', // black text
                borderRadius: '18px',
                border: '1px solid blue',
                fontSize: '12px',
                fontWeight: 'regular',
                fontFamily: 'Arial,  sans-serif',
                lineHeight: 1.4,
              }}
            >
              New
            </div>
          )}
        </div>
      ),
    },
  ];

  const IsInActiveStatus = reviewStatus => {
    setActiveStatus(showActiveStatusIn.includes(reviewStatus));
  };
  const centerColumnsWrapper = () => {
    return activeStatus ? centerColumns : centerColumnsWoActive;
  };
  const classroomColumnsWrapper = () => {
    return activeStatus ? classroomColumns : classroomColumnsWoActive;
  };

  const IsCenterSaveButtonDisabled = () => {
    return newCenterName.trim() === '' || newClassrooms[0].trim() === '';
  };

  const IsClassroomSaveButtonDisabled = () => {
    return newClassrooms[0].trim() === '';
  };

  const IsClassroomUpdateButtonDisabled = () => {
    return editingClassroom?.classRoomName === '';
  };

  const IsCenterUpdateButtonDisabled = () => {
    return editingCenter?.centerName === '';
  };

  const handleTableChangeCenter = (type, { sizePerPage, page }) => {
    setCenterLimit(sizePerPage);
    setCenterPage(page);
  };
  const handleTableChangeClassroom = (type, { sizePerPage, page }) => {
    setClassLimit(sizePerPage);
    setClassPage(page);
  };
  const panes = [
    {
      menuItem: 'Centers',
      render: () => (
        <Tab.Pane>
          <BootstrapTableNext
            data={centers?.data || []}
            columns={centerColumnsWrapper()}
            striped
            hover
            keyField="centerName"
            bordered={false}
            remote={{ pagination: true, sort: true }}
            pagination={paginationFactory(paginationOptionsCenter)}
            onTableChange={handleTableChangeCenter}
          />
          {activeStatus && (
            <Button primary onClick={() => setShowAddCenterModal(true)}>
              Add Center
            </Button>
          )}
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Classrooms',
      render: () => (
        <Tab.Pane>
          <Form>
            <Form.Group>
              <Form.Field width={8}>
                <label> Center </label>
                <Dropdown
                  placeholder="Select Center"
                  fluid
                  selection
                  value={selectedCenter}
                  options={activeCenters?.map(center => ({
                    key: center.centerId,
                    text: center.centerName,
                    value: center.centerId,
                  }))}
                  onChange={(e, { value }) => {
                    setSelectedCenter(value);
                  }}
                />
              </Form.Field>
            </Form.Group>
          </Form>
          <hr />
          <BootstrapTableNext
            data={classrooms?.data}
            columns={classroomColumnsWrapper()}
            striped
            hover
            keyField="classSampleId"
            bordered={false}
            remote={{ pagination: true, sort: true }}
            pagination={paginationFactory(paginationOptionsClass)}
            onTableChange={handleTableChangeClassroom}
          />
          {activeStatus && (
            <Button primary onClick={() => setShowAddClassroomModal(true)}>
              Add Classroom
            </Button>
          )}
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div>
      <Tab panes={panes} />

      <Modal
        open={showAddCenterModal}
        onClose={() => {
          resetModal();
          setShowAddCenterModal(false);
        }}
      >
        <Modal.Header>
          Add New Center
          <Icon
            name="close"
            onClick={() => {
              resetModal();
              setShowAddCenterModal(false);
            }}
            size="small"
            style={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              cursor: 'pointer',
            }}
          />
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group
              width={12}
              style={{ marginBottom: '1rem', padding: '7px' }}
            >
              <Form.Input
                width={12}
                label="Center Name"
                value={newCenterName}
                onChange={e => setNewCenterName(e.target.value)}
              />
            </Form.Group>
            <div
              style={{
                maxHeight: '300px',
                overflowX: 'hidden',
                overflowY: 'auto',
              }}
            >
              {newClassrooms.map((classroom, index) => (
                <Form.Group
                  key={index}
                  width={12}
                  style={{ marginBottom: '1rem', padding: '7px' }}
                >
                  <Form.Input
                    width={12}
                    key={index}
                    label={`Classroom Name`}
                    value={classroom}
                    onChange={e => {
                      const updatedClassrooms = [...newClassrooms];
                      updatedClassrooms[index] = e.target.value;
                      setNewClassrooms(updatedClassrooms);
                    }}
                    style={{ marginRight: '10px' }}
                  />
                  {index > 0 && (
                    <Button
                      className="eas-delete-button"
                      onClick={() => removeClassroomField(index)}
                      style={{
                        backgroundColor: '#db2828',
                        position: index == 0 ? 'absolute' : 'relative',
                        right: '0px',
                        top: '10px',
                        color: 'white',
                        cursor: 'pointer',
                      }}
                    >
                      Remove Classroom
                    </Button>
                  )}
                </Form.Group>
              ))}
            </div>
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                addNewClassroomField();
              }}
              style={{ color: 'blue', textDecoration: 'underline' }}
            >
              Add another classroom
            </a>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              resetModal();
              setShowAddCenterModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            primary
            onClick={addNewCenter}
            disabled={IsCenterSaveButtonDisabled()}
          >
            Add
          </Button>
        </Modal.Actions>
      </Modal>
      {showErrorAlert && (
        <AmsAlert
          show={showErrorAlert}
          title="Oops!"
          text={errorMessage}
          type={'error'}
          showConfirm
          onCancel={() => {
            setShowErrorAlert(false);
            setErrorMessage('');
          }}
          onConfirm={() => {
            setShowErrorAlert(false);
            setErrorMessage('');
          }}
        />
      )}
      <Modal
        open={showAddClassroomModal}
        onClose={() => {
          resetModal();
          setShowAddClassroomModal(false);
        }}
      >
        <Modal.Header>
          Add New Classroom
          <Icon
            name="close"
            onClick={() => {
              resetModal();
              setShowAddClassroomModal(false);
            }}
            size="small"
            style={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              cursor: 'pointer',
            }}
          />
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field style={{ padding: '7px' }}>
              <label>
                Center:{' '}
                <label>
                  {activeCenters?.find(
                    center => center.centerId === selectedCenter
                  )?.centerName || ''}
                </label>
              </label>
            </Form.Field>
            <div
              style={{
                padding: '7px',
                maxHeight: '300px',
                overflowX: 'hidden',
                overflowY: 'auto',
              }}
            >
              {newClassrooms.map((classroom, index) => (
                <Form.Group
                  key={index}
                  width={12}
                  style={{ marginBottom: '1rem' }}
                >
                  <Form.Input
                    width={12}
                    key={index}
                    label={`Classroom Name`}
                    value={classroom}
                    onChange={e => {
                      const updatedClassrooms = [...newClassrooms];
                      updatedClassrooms[index] = e.target.value;
                      setNewClassrooms(updatedClassrooms);
                    }}
                    style={{ marginRight: '7px' }}
                  />
                  {index > 0 && (
                    <Button
                      className="eas-delete-button"
                      onClick={() => removeClassroomField(index)}
                      style={{
                        backgroundColor: '#db2828',
                        position: index == 0 ? 'absolute' : 'relative',
                        right: '1px',
                        top: '10px',
                        color: 'white',
                        cursor: 'pointer',
                      }}
                    >
                      Remove Classroom
                    </Button>
                  )}
                </Form.Group>
              ))}
            </div>
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                addNewClassroomField();
              }}
              style={{ color: 'blue', textDecoration: 'underline' }}
            >
              Add another classroom
            </a>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              resetModal();
              setShowAddClassroomModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            primary
            onClick={addNewClassroom}
            disabled={IsClassroomSaveButtonDisabled()}
          >
            Add
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        open={showEditCenterModal}
        onClose={() => setShowEditCenterModal(false)}
      >
        <Modal.Header>
          Edit Center
          <Icon
            name="close"
            onClick={() => {
              setEditingCenter();
              setShowEditCenterModal(false);
            }}
            size="small"
            style={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              cursor: 'pointer',
            }}
          />
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Input
              label="Center Name"
              value={editingCenter?.centerName || ''}
              onChange={e =>
                setEditingCenter({
                  ...editingCenter,
                  centerName: e.target.value,
                })
              }
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setEditingCenter();
              setShowEditCenterModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            primary
            onClick={editRecentCenter}
            disabled={IsCenterUpdateButtonDisabled()}
          >
            Update
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        open={showEditClassroomModal}
        onClose={() => setShowEditClassroomModal(false)}
      >
        <Modal.Header>
          Edit Classroom
          <Icon
            name="close"
            onClick={() => {
              resetModal();
              setShowEditClassroomModal(false);
            }}
            size="small"
            style={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              cursor: 'pointer',
            }}
          />
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>
                Center:{' '}
                <label>
                  {activeCenters?.find(
                    center => center.centerId === selectedCenter
                  )?.centerName || ''}
                </label>
              </label>
            </Form.Field>
            <Form.Input
              label="Classroom Name"
              value={editingClassroom?.classRoomName || ''}
              onChange={e =>
                setEditingClassroom({
                  ...editingClassroom,
                  classRoomName: e.target.value,
                })
              }
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setShowEditClassroomModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            primary
            onClick={editRecentClassroom}
            disabled={IsClassroomUpdateButtonDisabled()}
          >
            Update
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}
